import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Footer from 'components/Footer'
import PortfolioHeader from 'components/PortfolioHeader'

import SpirituneLogo from 'images/spiritune/spiritune-logo.png'
import downloadAppStore from 'images/spiritune/donwload-app-store.png'

import BackgroundSection from 'components/BackgroundSection'

import {
  MorningIcon,
  StudyingIcon,
  TransportIcon,
  RelaxingIcon,
  SleepIcon,
  Ball,
  MusicalNote,
  Cube,
  ChatThink,
  CircleInsideHexagon,
} from 'components/Icons'

const Spiritune = () => {
  const {
    girl,
    wakingUp,
    windingDown,
    connect,
    playOnADevice,
    pbjLive,
    creatively,
    moeAssist,
    appPreview1,
    appPreview2,
    appPreview3,
    headerImage,
  } = useStaticQuery(
    graphql`
      query {
        girl: file(relativePath: { eq: "spiritune/girl.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        wakingUp: file(relativePath: { eq: "spiritune/waking-up.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        windingDown: file(relativePath: { eq: "spiritune/winding-down.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        connect: file(relativePath: { eq: "spiritune/connect.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        playOnADevice: file(
          relativePath: { eq: "spiritune/play-on-a-device.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        creatively: file(relativePath: { eq: "creatively-footer-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pbjLive: file(relativePath: { eq: "pbj-live-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        moeAssist: file(relativePath: { eq: "moe-assists-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        appPreview1: file(relativePath: { eq: "spiritune/app-preview-1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        appPreview2: file(relativePath: { eq: "spiritune/app-preview-2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        appPreview3: file(relativePath: { eq: "spiritune/app-preview-3.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        headerImage: file(relativePath: { eq: "spiritune/app-1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  const appPreviewImages = [appPreview1, appPreview2, appPreview3]

  return (
    <Layout>
      <SEO />
      <PortfolioHeader
        heading="Managing emotional well-being through music"
        image={headerImage}
        singleImage
        containerClassName="spiritune"
      />
      <div className="portfolio-wrapper">
        <div className="container">
          <section className="spiritune__mood">
            <div className="spiritune__mood-text">
              <h4 className="spiritune__mood-heading">
                Shift your emotional journey.
              </h4>
              <p className="spiritune__mood-description">
                Select your current and desired emotional state to inform a
                curated playlist of science-backed tracks. Follow the shifting
                color to see your journey progress.
              </p>
            </div>
            <div className="spiritune__mood-level">
              <div className="spiritune__mood-level-meter">
                <p
                  data-sal="slide-down"
                  data-sal-delay="200"
                  data-sal-easing="ease-out-back"
                >
                  Energetic
                </p>
                <p
                  data-sal="slide-down"
                  data-sal-delay="400"
                  data-sal-easing="ease-out-back"
                >
                  Anxious
                </p>
                <p
                  data-sal="slide-down"
                  data-sal-delay="600"
                  data-sal-easing="ease-out-back"
                >
                  Lethargic
                </p>
                <p
                  data-sal="slide-down"
                  data-sal-delay="800"
                  data-sal-easing="ease-out-back"
                >
                  Chill
                </p>
              </div>
              <div className="spiritune__mood-level-gradient" />
            </div>
          </section>
          <section
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="easeInBounce"
          >
            <BackgroundSection image={girl} className="spiritune_chilled">
              <h4 className="spiritune_chilled-heading">
                {'I want to feel '}
                <span
                  data-sal="zoom-out"
                  data-sal-delay="900"
                  data-sal-easing="easeInBounce"
                  className="underline"
                >
                  chill
                </span>
              </h4>
            </BackgroundSection>
          </section>
          <section className="spiritune_app">
            <div
              data-sal="zoom-in"
              data-sal-delay="300"
              data-sal-easing="easeInBounce"
            >
              <BackgroundSection
                image={wakingUp}
                className="spiritune_app-grid --waking-up"
              />
            </div>
            <div
              data-sal="zoom-out"
              data-sal-delay="600"
              data-sal-easing="easeInBounce"
            >
              <BackgroundSection
                image={windingDown}
                className="spiritune_app-grid --winding-down"
              />
            </div>
          </section>
          <section className="spiritune_day-cycle">
            <div className="spiritune_day-cycle-item">
              <p
                data-sal="zoom-in"
                data-sal-delay="1000"
                data-sal-easing="easeInBounce"
              >
                9AM
              </p>
              <MorningIcon
                data-sal="zoom-in"
                data-sal-delay="100"
                data-sal-easing="easeInBounce"
              />
              <p
                data-sal="zoom-in"
                data-sal-delay="1000"
                data-sal-easing="easeInBounce"
              >
                Waking Up
              </p>
            </div>
            <div className="spiritune_day-cycle-item">
              <p
                data-sal="zoom-in"
                data-sal-delay="900"
                data-sal-easing="easeInBounce"
              >
                11AM
              </p>
              <StudyingIcon
                data-sal="zoom-in"
                data-sal-delay="200"
                data-sal-easing="easeInBounce"
              />
              <p
                data-sal="zoom-in"
                data-sal-delay="900"
                data-sal-easing="easeInBounce"
              >
                Work Flow
              </p>
            </div>
            <div className="spiritune_day-cycle-item">
              <p
                data-sal="zoom-in"
                data-sal-delay="800"
                data-sal-easing="easeInBounce"
              >
                3PM
              </p>
              <TransportIcon
                data-sal="zoom-in"
                data-sal-delay="300"
                data-sal-easing="easeInBounce"
              />
              <p
                data-sal="zoom-in"
                data-sal-delay="800"
                data-sal-easing="easeInBounce"
              >
                On the Go
              </p>
            </div>
            <div className="spiritune_day-cycle-item">
              <p
                data-sal="zoom-in"
                data-sal-delay="700"
                data-sal-easing="easeInBounce"
              >
                8PM
              </p>
              <RelaxingIcon
                data-sal="zoom-in"
                data-sal-delay="400"
                data-sal-easing="easeInBounce"
              />
              <p
                data-sal="zoom-in"
                data-sal-delay="700"
                data-sal-easing="easeInBounce"
              >
                Winding Down
              </p>
            </div>
            <div className="spiritune_day-cycle-item">
              <p
                data-sal="zoom-in"
                data-sal-delay="600"
                data-sal-easing="easeInBounce"
              >
                10PM
              </p>
              <SleepIcon
                data-sal="zoom-in"
                data-sal-delay="500"
                data-sal-easing="easeInBounce"
              />
              <p
                data-sal="zoom-in"
                data-sal-delay="600"
                data-sal-easing="easeInBounce"
              >
                Sleep
              </p>
            </div>
          </section>
          <section className="spiritune__mood">
            <div className="spiritune__mood-text">
              <h4 className="spiritune__mood-heading --full">
                A data driven, scientific approach to music & brain health.
              </h4>
              <p className="spiritune__mood-description">
                Our daily routine can lead to a bombardment of auditory stimuli
                which may cause increased stress, cognitive impairment, and a
                decreased sense of well-being.
              </p>
            </div>
          </section>
          <section className="spiritune_app">
            <div
              data-sal="zoom-in"
              data-sal-delay="600"
              data-sal-easing="easeInBounce"
            >
              <BackgroundSection
                image={connect}
                className="spiritune_app-grid --bg-connect"
              />
            </div>
            <div
              data-sal="zoom-out"
              data-sal-delay="300"
              data-sal-easing="easeInBounce"
            >
              <BackgroundSection
                image={playOnADevice}
                className="spiritune_app-grid --play-on-device"
              />
            </div>
          </section>
          <section className="portfolio-icons --spiritune">
            <Ball data-sal="fade" data-sal-delay="500" />
            <MusicalNote data-sal="fade" data-sal-delay="400" />
            <Cube data-sal="fade" data-sal-delay="300" />
            <ChatThink data-sal="fade" data-sal-delay="200" />
            <CircleInsideHexagon data-sal="fade" data-sal-delay="100" />
          </section>
          <section>
            <BackgroundSection
              imageList={appPreviewImages}
              className="creatively_app-preview --spiritune-app-preview"
              isMultiBackground
            />
          </section>
          <section className="spiritune_app-download">
            <img
              src={SpirituneLogo}
              className="spiritune_app-download-logo"
              alt="spiritune logo"
              data-sal="zoom-in"
              data-sal-delay="300"
              data-sal-easing="easeInBounce"
            />
            <div className="spiritune_app-download-wrapper">
              <h4
                className="spiritune_app-download-text"
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-easing="easeInBounce"
              >
                Download on the App Store
              </h4>
              <a
                href="https://apps.apple.com/us/app/spiritune/id1455800918"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={downloadAppStore}
                  className="spiritune_app-download-img"
                  alt="download spiritune from app store"
                  data-sal="slide-down"
                  data-sal-delay="900"
                  data-sal-easing="easeInBounce"
                />
              </a>
            </div>
          </section>
          <section className="spiritune-app-navigation-footer">
            <h4 className="spiritune-app-navigation-footer-main-heading">
              See what else we’re building
            </h4>
            <div className="spiritune-app-navigation-footer-grid">
              <Link to="/portfolio/moe-assist">
                <BackgroundSection
                  image={moeAssist}
                  className="spiritune-app-navigation-footer-moe-assist"
                  data-sal="zoom-out"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    Moe Assist
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    A management tool for influenecers
                  </h4>
                </BackgroundSection>
              </Link>
              <Link to="/portfolio/pbj-live">
                <BackgroundSection
                  image={pbjLive}
                  className="spiritune-app-navigation-footer-pbj-live"
                  data-sal="zoom-out"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    PBJ Live
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    Your content - wherever, whenever
                  </h4>
                </BackgroundSection>
              </Link>
              <Link to="/portfolio/creatively">
                <BackgroundSection
                  image={creatively}
                  className="spiritune-app-navigation-footer-creatively"
                  data-sal="zoom-out"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    Creatively
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    Connecting the creative community
                  </h4>
                </BackgroundSection>
              </Link>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Spiritune
